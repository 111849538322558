const parityflexAPY = 5.95;
const fastbreakAPY = 6.15;
const steadypaceAPY = 6.15;

const formatValue = (value) => value.toFixed(2);

const getParityFlexRate = (showNew, unformatted) => {
  const value = parityflexAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getFastBreakRate = (showNew, unformatted) => {
  const value = fastbreakAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getSteadyPaceRate = (showNew, unformatted) => {
  const value = steadypaceAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

export { getParityFlexRate, getFastBreakRate, getSteadyPaceRate };
